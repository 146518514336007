<template>
  <div class="init">
    <div class="container1" >
      <div class="slider1">
        <h3>↓↓ INGRESA DANDO CLICK EN LA IMAGEN ↓↓</h3>
        <br>
        <ul>
          <a href="https://evento.minedu.gob.bo/evento/223" target="_black">
            <li><img src="portada\Año_nuevo_andino.jpg"></li>
          </a>
          <!--<a href="https://evento.minedu.gob.bo/evento/223" target="_black">--
            <li><img src="portada\COCHABAMBA.jpg" ></li>
          </a>-->
          <!--<a href="http://dgfmegi.minedu.gob.bo" target="_black">
            <li><img src="portada\convocatoria.jpg" loading="lazy"></li>
          </a>-->
        </ul>
      </div>
    </div>
    <div class="section-docs padding-bottom">
      <div class="container">
        <h3>NORMATIVAS</h3>
        <!--<div>    
          !--<span class="btngroup">
            <a href="https://dgfmapi.minedu.gob.bo/normativas/1625239743889.pdf"
              class="btngroup-btn"
              target="_blank"
              >CONSTITUCION</a
            >

            <a href="https://dgfmapi.minedu.gob.bo/normativas/1625239834482.pdf"
              class="btngroup-btn"
              target="_blank"
              >LEY N° 070</a
            >
            
            <a href="https://dgfmapi.minedu.gob.bo/normativas/1625239932693.pdf"
              class="btngroup-btn"
              target="_blank"
              >COMPENDIO</a
            >--

            --<span class="dropdown">
            <button class="dropbtn">COMPENDIO</button>
            <div class="dropdown-content">
              <a href="https://dgfmapi.minedu.gob.bo/normativas/1625239932693.pdf" target="_black">VERSION 2018</a>
              <a href="#">VERSION 2023</a>
            </div>
            </span>--

          </span> -->


          <!--iv class="btn-group" role="group" aria-label="Button group with nested dropdown">
            <button type="button" class="btn btn-secondary">1</button>
            <button type="button" class="btn btn-secondary">2</button>

            <div class="btn-group" role="group">
              <button id="btnGroupDrop1" type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Dropdown
              </button>
              <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                <a class="dropdown-item" href="#">Dropdown link</a>
                <a class="dropdown-item" href="#">Dropdown link</a>
              </div>
            </div>
          </div>-->


          <!--<div class="dropdown">
            <button class="dropbtn btngroup dropdown">COMPENDIO</button>
            <div class="dropdown-content">
              <a href="https://dgfmapi.minedu.gob.bo/normativas/1625239932693.pdf" target="_black">VERSION 2018</a>
              <a href="#">VERSION 2023</a>
            </div>
          </div>--
        </div>-->
              
        <span class="btngroup">
          <a
            v-for="(item, index) in normativas"
            :key="index"
            :href="`${serve}/normativas/${item.documento}`"
            class="btngroup-btn"
            target="_blank"
            download
            >{{ item.cite }}</a
          >
        </span> 
      </div>
    </div>
    <div class="section padding-bottom">
      <div class="container">
        <div class="main-grid">
          <div id="box-1" class="feature-icon-top-v-1">
            <div class="feature-icon">
              <img src="icons/documento.gif" loading="lazy" alt="icon-window"  />
            </div>
            <h3 class="h3">SIFM</h3>
            <p>Sistema de Información de Formación de Maestros.</p>
            <a
              href="http://sifm.minedu.gob.bo"
              target="_blank"
              class="secondary-small-button w-button margin-top"
            >
              Ir a enlace
            </a>
          </div>
          <div id="box-2" class="feature-icon-top-v-1">
            <div class="feature-icon">
              <img src="icons/cuaderno.gif" loading="lazy" alt="icon-layer" />
            </div>
            <h3 class="h3">SAPP</h3>
            <p>Sistema de Actualización de Pedagógica Permanente</p>
            <a
              href="http://dgfmsapp.minedu.gob.bo"
              target="_blank"
              class="secondary-small-button w-button margin-top"
            >
              Ir a enlace
            </a>
          </div>
          <div id="box-3" class="feature-icon-top-v-1">
            <div class="feature-icon">
              <img src="icons/lista-de-verificacion.gif" loading="lazy" alt="icon-settings" />
            </div>
            <h3 class="h3"><strong>PNA</strong></h3>
            <p>Sistema del Programa de Nivelación Académica</p>
            <a
              href="http://nivelacionacademica.minedu.gob.bo"
              target="_blank"
              class="secondary-small-button w-button margin-top"
            >
              Ir a enlace
            </a>
          </div>
          <div id="box-4" class="feature-icon-top-v-1">
            <div class="feature-icon">
              <img src="icons/diploma.gif" loading="lazy" alt="icon-chat" />
            </div>
            <h3 class="h3">SIFM Egresados</h3>
            <p>Sistema de Formación de Maestr@s Egresados</p>
            <a
              href="http://sifmegresados.minedu.gob.bo"
              target="_blank"
              class="secondary-small-button w-button margin-top"
            >
              Ir a enlace
            </a>
          </div>
        </div>
      </div>
      <div class="container" style="margin-top: 1.5rem">
        <div class="main-grid">
          <div id="box-5" class="feature-icon-top-v-1">
            <div class="feature-icon">
              <img src="icons/redes-sociales.gif" loading="lazy" alt="icon-layer" />
            </div>
            <h3 class="h3">EGI</h3>
            <p>Sistema de Gestión Institucional</p>
            <a
              href="https://dgfmegi.minedu.gob.bo"
              target="_blank"
              class="secondary-small-button w-button margin-top"
            >
              Ir a enlace
            </a>
          </div>
          <div id="box-6" class="feature-icon-top-v-1">
            <div class="feature-icon">
              <img src="icons/libros.gif" loading="lazy" alt="icon-settings" />
            </div>
            <h3 class="h3"><strong>EFI</strong></h3>
            <p>Sistema de Formación Inicial</p>
            <a
              href="https://dgfmefi.minedu.gob.bo"
              target="_blank"
              class="secondary-small-button w-button margin-top"
            >
              Ir a enlace
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="section padding-bottom">
      <div class="container">
        <div class="title-container">
          <h2 class="h2">Homepage</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima
            aspernatur odit animi optio corrupti doloribus reiciendis. Minus
            excepturi earum et nam deserunt ipsum, quos est necessitatibus
            repellat rerum.<br />
          </p>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Service from "../router/endpoint";
import axios from "axios";
export default {
  name: "home",
  data() {
    return {
      loading: false,
      noticia: {
        titulo: "",
        descripcion: "",
        fecha: "",
        imagen: "",
      },
      normativas: [],
      serve: "",
    };
  },
  mounted() {
    this.serve = Service.getServe();
    this.getNoticia();
  },
  methods: {
    getNoticia() {
      this.loading = true;
      axios
        .get(Service.getBase() + "inicio/noticia", Service.getHeader())
        .then((response) => {
          this.loading = false;
          this.noticia = response.data.noticia;
          this.normativas = response.data.normativas;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
  },
};
</script>

